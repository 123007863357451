<template>
  <div>
    <DocumentsList />
  </div>
</template>

<script>
import DocumentsList from "@/components/documents-list/DealerDocumentsList"

export default {
  name: "DealerDocuments",
  components: {
    DocumentsList,
  },
}
</script>

<style scoped></style>
